const category = [
  {
    display: "T-shirt",
    categorySlug: "T-shirt",
  },
  {
    display: "Dress shirt",
    categorySlug: "Dress shirt",
  },
  {
    display: "Jeans",
    categorySlug: "jeans",
  },
];

export default category