import React from 'react'

const Login = () => {
  return (
    <div>
      Login page under development
    </div>
  )
}

export default Login
