const img1 = require("../images/slider/slide_1.png").default
const img2 = require("../images/slider/slide_2.png").default
const img3 = require("../images/slider/slide_3.png").default

const heroSliderData = [
  {
    title: "JaydenKyle Collections",
    description:
      "Speaking of sophistication, one cannot ignore the Pima fabric line. It provides the highest quality for any fashion product. This makes the polo shirt structure strong, durable, minimizes pilling, soft to the touch, colorfast, and ensures the utmost user comfort.",
    img: img1,
    color: "blue",
    path: "/catalog/ao-thun-dinosaur-01",
  },
  {
    title: "Women's Relaxed Fit Modal Polo",
    description:
      "The women's relaxed fit Modal polo shirt utilizes premium fabric technology that is environmentally friendly and exclusively designed to resist fabric shrinking. Modal fabric women's polo shirts are suitable for those with dynamic working environments like today.",
    img: img2,
    path: "/catalog/ao-thun-dinosaur-02",
    color: "pink",
  },
  {
    title: "Lacoste Women's Coolmax Polo",
    description:
      "The women's polo shirt model is made from Coolmax material, providing a cool and refreshing feeling when worn. The design of this Coolmax polo shirt is extremely comfortable, offering a neat appearance, and it promises to be a very trendy polo shirt in the upcoming period.",
    img: img3,
    path: "/catalog/ao-thun-dinosaur-03",
    color: "orange",
  },
];

export default heroSliderData