import React from 'react'

import { Link } from 'react-router-dom'

import Grid from './Grid'

import logo from '../assets/images/jay.jpg'

const footerAboutLinks = [
    {
        display: "Introduce",
        path: "/about"
    },
    {
        display: "Contact",
        path: "/about"
    },
    {
        display: "Recruitment",
        path: "/about"
    },
    {
        display: "Store",
        path: "/about"
    }
]

const footerCustomerLinks = [
    {
        display: "Return policy",
        path: "/about"
    },
    {
        display: "Warranty policy",
        path: "/about"
    },
    {
        display: "Refund policy",
        path: "/about"
    }
]
const Footer = () => {
    return (
      <footer className="footer">
        <div className="container">
          <Grid col={4} mdCol={2} smCol={1} gap={10}>
            <div>
              <div className="footer__title">Support</div>
              <div className="footer__content">
                <p>
                  Contact for Ordering <strong>(+254) 710 513 252</strong>
                </p>
                <p>
                  Order enquiries <strong>(+254) 701 330 954</strong>
                </p>
                <p>
                  Feedback / Complains  <strong>(+254) 701 330 954</strong>
                </p>
              </div>
            </div>
            <div>
              <div className="footer__title">About us</div>
              <div className="footer__content">
                {footerAboutLinks.map((item, index) => (
                  <p key={index}>
                    <Link to={item.path}>{item.display}</Link>
                  </p>
                ))}
              </div>
            </div>
            <div>
              <div className="footer__title">Customer care</div>
              <div className="footer__content">
                {footerCustomerLinks.map((item, index) => (
                  <p key={index}>
                    <Link to={item.path}>{item.display}</Link>
                  </p>
                ))}
              </div>
            </div>
            <div className="footer__about">
              <p>
                <Link to="/">
                  <img src={logo} className="footer__logo" alt="" />
                </Link>
              </p>
              <p>
                To strive towards the goal of bringing daily clothing joy to
                millions of our most esteemed consumers. Let's work together
                with Jayden kyle collections towards a more dynamic and positive
                lifestyle.
              </p>
            </div>
          </Grid>
        </div>
      </footer>
    );
}

export default Footer
