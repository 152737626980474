const colors = [
    {
        display: "White",
        color: "white"
    },
    {
        display: "Pink",
        color: "pink"
    },
    {
        display: "Black",
        color: "black"
    },
    {
        display: "Yellow",
        color: "yellow"
    },
    {
        display: "Orange",
        color: "orange"
    },
    {
        display: "Blue",
        color: "blue"
    }
]

export default colors